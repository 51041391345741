import BackendService from './BackendService';

export default class H2PService {
    static download(data){
 
        let payload = {
            "html": data
        }
 
        return BackendService.post("/html2pdf/convert", payload, true).then(resp => {
            return resp
        })
    }

}